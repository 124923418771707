import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ky from "ky";
import { useState } from "react";
import { Divider, Snackbar } from "@mui/material";
import logo from "./logo.png";
import { purple, yellow, red, blue, green } from "@mui/material/colors";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      <Link color="inherit" href="https://andrewdev.eu/">
        {"Il culto del carro ti inghiottirà"}
      </Link>
      {" - "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const colors: any[] = [
  purple[500],
  blue[500],
  yellow[500],
  green[500],
  red[500],
];

const theme = createTheme({
  palette: {
    primary: {
      main: colors[Math.floor(Math.random() * 5)],
    },
  },
});

export default function Entry() {
  const [key, setKey] = useState("");
  const [keyp, setKeyp] = useState("");
  const [size, setSize] = useState("");
  const [pin, setPin] = useState("");
  const [generated, setGenerated] = useState("");
  const [copied, setCopied] = useState(false);
  const [savePin, setSavePin] = useState(false);

  React.useEffect(() => {
    const storage = localStorage.getItem("pin");
    if (storage) {
      setPin(storage);
      setSavePin(true);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar> */}
          <img alt="logo" src={logo} style={{ width: 100 }} />
          <Typography component="h1" variant="h5">
            Andrew Auth
          </Typography>

          <TextField
            margin="normal"
            fullWidth
            id="pub"
            label="Public Service"
            name="pub"
            value={key}
            onChange={(el) => {
              const text = el.currentTarget.value;
              if (text !== undefined) {
                setKey(text.trim());
              }
            }}
            autoFocus
          />
          <TextField
            margin="normal"
            fullWidth
            id="pvt"
            label="Private Service"
            name="pvt"
            value={keyp}
            onChange={(el) => {
              const text = el.currentTarget.value;
              if (text !== undefined) {
                setKeyp(text.trim());
              }
            }}
          />
          <TextField
            margin="normal"
            fullWidth
            id="size"
            label="Size"
            name="size"
            value={size.toString()}
            onChange={(el) => {
              const text = el.currentTarget.value;
              if (
                text !== undefined &&
                !Number.isNaN(Number.parseFloat(text))
              ) {
                setSize(text);
              }
            }}
          />
          <TextField
            margin="normal"
            fullWidth
            id="PIN"
            label="PIN"
            name="PIN"
            value={pin}
            onChange={(el) => {
              const text = el.currentTarget.value;
              if (text !== undefined) {
                setPin(text.trim());
              }
            }}
          />

          <FormControlLabel
            control={
              <Checkbox
                value="remember"
                color="primary"
                checked={savePin}
                onChange={(evt: any, checked: boolean) => {
                  setSavePin(checked);
                  if (!checked) {
                    localStorage.removeItem("pin");
                  }
                }}
              />
            }
            label="Store your pin"
          />
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={async () => {
              const result = await ky.get(
                "https://pin.andrewdev.eu/auth.php?key=" +
                  encodeURIComponent(key) +
                  "&keyp=" +
                  encodeURIComponent(keyp) +
                  "&size=" +
                  encodeURIComponent(size) +
                  "&pin=" +
                  encodeURIComponent(pin)
              );
              if (result.ok) {
                const temp = await result.json();
                setGenerated(temp.kftr);
                if (savePin) {
                  localStorage.setItem("pin", pin);
                }
              }
            }}
          >
            Generate
          </Button>
          <Divider>Generated Code</Divider>
          <h3>{generated}</h3>
          <Button
            fullWidth
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            style={{ display: generated ? "block" : "none" }}
            onClick={() => {
              setCopied(true);
              navigator.clipboard.writeText(generated);
            }}
          >
            Copy
          </Button>
        </Box>
        <Snackbar
          open={copied}
          autoHideDuration={6000}
          onClose={() => {
            setCopied(false);
          }}
          message="Copied!"
        />
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
